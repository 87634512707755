<template>
  <v-dialog
    persistent
    v-model="dialog"
    hide-overlay
    max-width="60%"
    height="70vh"
  >
    <v-toolbar flat dark dense sticky color="primary" elevation="0">
      <v-toolbar-title class="text-lg-h6"> Summary</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="currReview.documentStatus === 'Open'"
        dark
        text
        @click.prevent="submitAppraisal"
      >
        Submit
      </v-btn>
      <v-btn v-else dark text @click.prevent="reviewAppraisal"> Submit </v-btn>
      <v-icon left color="white" @click="setDialog = false"> mdi-close </v-icon>
    </v-toolbar>
    <v-card>
      <v-card-text
        class="overflow-y-auto overflow-x-hidden"
        style="max-height: 70vh"
      >
        <v-alert class="ma-3" text type="info" dense>
          Please confirm all KPIs are rated before submission. Click on the
          Review Objectives to open details.
        </v-alert>
        <v-expansion-panels
          flat
          v-model="panel"
          class="pt-3 pb-3 ma-3"
          multiple
        >
          <v-expansion-panel expand v-model="panel" class="mt-1">
            <v-card outlined>
              <v-expansion-panel-header
                color="grey darken-2"
                class="rounded-b-0"
                expand-icon=""
              >
                <v-row class="d-flex">
                  <div class="mt-3">
                    <div class="text-h6 white--text mb-2">
                      Objectives
                      <v-chip x-small dark class="blue">
                        {{ reviewObjectives.length }}
                      </v-chip>
                    </div>
                  </div>
                  <v-spacer></v-spacer>
                </v-row>
              </v-expansion-panel-header>
              <div class="ma-2">
                <v-expansion-panels flat>
                  <v-expansion-panel
                    expand
                    class="mt-1"
                    v-for="(item, i) in reviewObjectives"
                    :key="i"
                  >
                    <v-card flat>
                      <v-expansion-panel-header
                        color="grey lighten-3"
                        class="rounded-b-0"
                      >
                        <template v-slot:actions>
                          <v-icon
                            color="error"
                            v-if="allKPIsRated(item.reviewKPI)"
                            >error</v-icon
                          >
                          <v-icon color="success" v-else
                            >mdi-checkbox-marked-circle</v-icon
                          >
                          <v-icon class="icon">$expand</v-icon>
                        </template>
                        <v-row class="d-flex">
                          <div class="mt-3">
                            <div class="text-subtitle-1 mb-2">
                              Objective {{ i + 1 }}:
                              <v-divider vertical />
                              {{ item.description }}
                              <v-chip x-small dark class="orange">
                                {{ item.reviewKPI.length }}
                              </v-chip>
                              <v-spacer />
                            </div>
                          </div>
                          <v-spacer></v-spacer>
                        </v-row>
                      </v-expansion-panel-header>
                    </v-card>
                    <v-expansion-panel-content class="mt-2">
                      <v-card flat class="rounded-b-0">
                        <v-expansion-panel-content
                          v-for="(item, i) in item.reviewKPI"
                          :key="i"
                        >
                          <v-card outlined flat color="blue-grey lighten-5">
                            <div class="mt-2 ml-3">
                              <span class="mt-2 text-body-2">
                                KPI {{ i + 1 }} :
                              </span>
                              <span>{{ item.description }}</span>
                              <v-icon
                                v-if="
                                  item.selfRatingScore === '' ||
                                  (!selfEdit && item.reviewerRatingScore === '')
                                "
                                color="error"
                                >error</v-icon
                              >
                              <v-icon color="success" v-else
                                >mdi-checkbox-marked-circle</v-icon
                              >

                              <br />

                              <span
                                class="mt-2 text-body-2"
                                v-if="item.selfRatingScore !== ''"
                              >
                                Self Rating Score :
                              </span>
                              <span class="text-button">{{
                                item.selfRatingScore
                              }}</span
                              ><br />

                              <span
                                class="mt-2 text-body-2"
                                v-if="item.reviewerRatingScore !== ''"
                              >
                                Reviewer Rating Score :
                              </span>
                              <span class="text-button">{{
                                item.reviewerRatingScore
                              }}</span>
                              <v-divider />
                            </div>
                            <v-simple-table class="ml-3">
                              <template v-slot:default>
                                <tbody>
                                  <tr
                                    v-for="(item, i) in item.kpiComments"
                                    :key="i"
                                  >
                                    <td class="font-weight-bold">
                                      {{ item.author }} {{ item.type }}
                                    </td>
                                    <td v-if="item.type === 'Comment'">
                                      {{ item.Comment }}
                                    </td>
                                    <td v-else>
                                      <v-tooltip bottom>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-btn
                                            text
                                            color="primary"
                                            elevation="0"
                                            class="white--text text-capitalize"
                                            @click="
                                              download(item.base64Attachment)
                                            "
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <v-icon class="mx-2"
                                              >mdi-download</v-icon
                                            >
                                          </v-btn>
                                        </template>
                                        <span>Download</span>
                                      </v-tooltip>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-form v-model="isValid" ref="submitForm">
              <v-textarea
                v-if="selfEdit"
                label="Final Remarks"
                dense
                outlined
                rows="2"
                :rules="rules.required"
                v-model="currReview.empRemarks"
                :disabled="myRatedKPIs < kpiLength"
              ></v-textarea>
              <v-textarea
                v-if="!selfEdit"
                label="Final Remarks"
                dense
                outlined
                rows="2"
                :rules="rules.required"
                v-model="currReview.appraiserRemarks"
                :disabled="supervisorRatedKPIs < kpiLength"
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import helpers from '@kinetics254/cassandra-base/utilities/helpers/helper'
import AppraisalMixin from "../../appraisalv1/AppraisalMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";

export default {
  mixins: [AppraisalMixin, FileMixin],

  props: {
    reviewObjectives: Array,
    dialog: Boolean,
    currReview: Object,
    status: String,
    prevRoute: Object,
    selfEdit: Boolean,
    kpiLength: Number,
  },
  data: () => ({
    panel: [0],
    objectivespanel: [],
    isValid: false,
  }),
  name: "appraisalSummary",
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "This field is required"],
      };
    },
    supervisorRatedKPIs() {
      let count = 0;
      if (this.currReview) {
        this.currReview.reviewObjectives.forEach((obj) => {
          obj.reviewKPI.forEach(function (e) {
            if (e.reviewerRatingScore !== "") {
              count++;
            }
          });
        });
      }
      return count;
    },
    myRatedKPIs() {
      let count = 0;
      this.currReview.reviewObjectives.forEach((obj) => {
        obj.reviewKPI.forEach(function (e) {
          if (e.selfRatingScore !== "") {
            count++;
          }
        });
      });
      return count;
    },
  },
  methods: {
    // submit appraisal to reviewer and save final remarks
    submitAppraisal: function () {
      this.$confirm.show({
        title: "Submit this appraisal?",
        text: "This action is irreversible",
        onConfirm: () => {
          if (this.myRatedKPIs === this.kpiLength) {
            if (!this.isValid) {
              this.$refs.submitForm.validate();
            } else {
              const data = { ...this.currReview };
              delete data.employeeName;
              delete data.overallScore;
              this.$store.dispatch("appraisal/addEmployeeRemarks", data);
              this.$router.push({ path: "/appraisal/list" });
            }
          } else {
            Event.$emit("ApiError", "Please Make sure all Kpis are rated");
            this.setDialog = false;
            Event.$emit("back");
          }
        },
      });
    },
    // submit appraisal to supervisor review and save final remarks
    reviewAppraisal: function () {
      this.$confirm.show({
        title: "Submit this appraisal?",
        text: "This action is irreversible",
        onConfirm: () => {
          if (this.supervisorRatedKPIs === this.kpiLength) {
            if (!this.isValid) {
              this.$refs.submitForm.validate();
            } else {
              const data = { ...this.currReview };
              delete data.employeeName;
              delete data.overallScore;
              this.$store.dispatch("appraisal/addSupervisorRemarks", data);
              this.$router.push({ path: "/appraisal/supervisor-reviews/list" });
            }
          } else {
            Event.$emit("ApiError", "Please Make sure all Kpis are rated");
            this.setDialog = false;
            Event.$emit("back");
          }
        },
      });
    },
    allKPIsRated(item) {
      if (this.selfEdit) {
        const count = item.filter((obj) => {
          if (obj.selfRatingScore === "") {
            return true;
          }
          return false;
        }).length;
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        const count = item.filter((obj) => {
          if (obj.reviewerRatingScore === "") {
            return true;
          }

          return false;
        }).length;
        if (count > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    download(item) {
      return this.downloadLink(process.env.VUE_APP_FILE_API + item);
    },
  },
};
</script>
